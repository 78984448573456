export const useGeneralStore = defineStore('general', {
  state: () => {
    return {
      langs: [
        { id: 'en', text: useT('event.language.english'), icon: 'custom/flag-en' },
        { id: 'es', text: useT('event.language.spanish'), icon: 'custom/flag-es' },
        { id: 'fr', text: useT('event.language.french'), icon: 'custom/flag-fr' },
        { id: 'cs', text: useT('event.language.czech'), icon: 'custom/flag-cz' },
        { id: 'sk', text: useT('event.language.slovak'), icon: 'custom/flag-sk' },
        { id: 'de', text: useT('event.language.german'), icon: 'custom/flag-de' },
        { id: 'pl', text: useT('event.language.polish'), icon: 'custom/flag-pl' },
        { id: 'hu', text: useT('event.language.hungarian'), icon: 'custom/flag-hun' },
      ],
      isBurgerMenuOpen: false,
    };
  },

  getters: {
    getLang: (state) => (langId) => {
      return state.langs?.find((lang) => lang?.id === langId);
    },
    getLangs: (state) => {
      return (locale) => {
        if (locale === 'cs') {
          const czechLangsOrder = ['cs', 'en', 'sk', 'pl', 'de', 'es', 'fr', 'hu'];
          return _sortBy(state.langs, (lang) => czechLangsOrder.indexOf(lang.id));
        }

        return state.langs;
      };
    },
  },
});
