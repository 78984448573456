export const useEventStore = defineStore('event', {
  state: () => {
    return {
      event: null,
      activePageTemplate: null,
      badgeRollTypes: [
        {
          id: 'slim',
          text: useT('event.checkIn.badgePrinting.small'),
        },
        {
          id: 'large',
          text: useT('event.checkIn.badgePrinting.big'),
        },
      ],
      feedbackSurveyQuestionTypes: [
        {
          id: 'numberScale',
          label: useT('event.feedback.numberScale.title'),
          placeholder: useT('event.feedback.numberScale.placeholder'),
          icon: 'star-01',
        },
        {
          id: 'optionSelect',
          label: useT('event.propertyType.select'),
          placeholder: useT('event.feedback.optionSelect.title'),
          description: useT('event.feedback.optionSelect.description'),
          type: 'options',
          icon: 'custom/radio',
        },
        {
          id: 'plainText',
          label: useT('event.feedback.plainText.title'),
          placeholder: useT('event.feedback.plainText.description'),
          icon: 'type-square',
        },
      ],
      propertyTypes: [
        {
          id: 'text',
          text: useT('event.propertyType.text'),
          icon: 'type-square',
        },
        {
          id: 'select',
          text: useT('event.propertyType.select'),
          icon: 'custom/radio',
        },
        {
          id: 'multiSelect',
          text: useT('event.propertyType.multiSelect'),
          icon: 'custom/checkbox',
        },
      ],
    };
  },

  actions: {
    // Event
    async refreshEvent() {
      await this.fetchEvent({ _id: this.event._id });
    },

    async fetchEvent({ _id }) {
      const response = await useApi.get(`/events/${_id}`).catch((error) => {
        if (error.response?.status === 403) {
          if (error.response._data === 'plan.expired') {
            useToast.error(useT('event.planExpired'));
          } else {
            useToast.error(useT('event.plan.invalid'));
          }
        } else {
          useToast.error(useT('common.errorLoadingData'));
        }

        navigateTo('/');
      });

      if (response) {
        this.event = response;

        if (this.event?.pages?.activeTemplate) {
          this.fetchActivePageTemplate();
        }
      }
    },

    async getEventPublic({ _id }) {
      const response = await useApi.get(`/public/events/${_id}`).catch((error) => {
        if (error.response?.status === 403) {
          if (error.response._data === 'plan.expired') {
            useToast.error(useT('event.page.unavailable'));
          } else {
            useToast.error(useT('event.plan.invalid'));
          }
        } else if (error.response?.status === 404) {
          // show 404 page instead of toast
        } else {
          useToast.error(useT('common.errorLoadingData'));
        }
      });

      return response;
    },

    async addEvent(body) {
      const event = await useApi.post('/events', { body }).catch((error) => {
        if (error.response?.status === 403) {
          useToast.error(useT('event.planUsedByOtherUser'));
        } else {
          useToast.error(useT('common.errorSavingChanges'));
        }
      });

      return event;
    },

    async updateEvent(body) {
      const event = await useApi.patch(`/events/${this.event._id}`, { body }).catch(() => {
        useToast.error(useT('common.errorSavingChanges'));
      });

      if (event) {
        this.event = event;
        return this.event;
      }
    },

    // Event/GuestList
    async updateGuestlist(body) {
      const event = await useApi.put(`/events/${this.event._id}/guestlist`, { body }).catch(() => {
        useToast.error(useT('common.errorSavingChanges'));
      });

      if (event) {
        this.event = {
          ...this.event,
          guestlist: event.guestlist,
        };
        return this.event;
      }
    },

    async updateGuestlistProperty({ propertyId, body }) {
      const properties = await useApi.patch(`/events/${this.event._id}/guestlist/properties/${propertyId}`, { body }).catch(() => {
        useToast.error(useT('event.property.canNotChange'));
      });

      if (Array.isArray(properties)) {
        this.event = {
          ...this.event,
          guestlist: {
            ...this.event.guestlist,
            properties,
          },
        };
        return properties;
      }
    },

    // Event/CheckIn
    async updateCheckIn(body) {
      const event = await useApi.put(`/events/${this.event._id}/check-in`, { body }).catch(() => {
        useToast.error(useT('common.errorSavingChanges'));
      });

      if (event) {
        this.event = {
          ...this.event,
          checkIn: event.checkIn,
        };
        return this.event;
      }
    },

    // Event/Activate guest app
    async activateGuestApp(body) {
      const event = await useApi.post(`/events/${this.event._id}/activate-guest-app`, { body }).catch(() => {
        useToast.error(useT('common.errorSavingChanges'));
      });

      return event;
    },

    // Event/Collaborators
    async addCollaborators(userIds) {
      const collaborators = await useApi.post(`/events/${this.event._id}/collaborators`, { body: userIds }).catch(() => {
        useToast.error(useT('event.collaborator.added.error'));
      });

      if (collaborators) {
        this.event.collaborators = collaborators;
      }
    },

    async removeCollaborator({ userId }) {
      const collaborators = await useApi.delete(`/events/${this.event._id}/collaborators/${userId}`).catch(() => {
        useToast.error(useT('event.collaborator.removed.error'));
      });

      if (collaborators) {
        this.event.collaborators = collaborators;
      }
    },

    // Event/Stages
    async addStage(body) {
      const response = await useApi.post(`/events/${this.event._id}/stages`, { body }).catch(() => {
        useToast.error(useT('common.errorSavingChanges'));
      });

      return response;
    },

    async updateStage({ id, body }) {
      const response = await useApi.put(`/events/${this.event._id}/stages/${id}`, { body }).catch(() => {
        useToast.error(useT('common.errorSavingChanges'));
      });

      return response;
    },

    async deleteStage(id) {
      const response = await useApi.delete(`/events/${this.event._id}/stages/${id}`).catch(() => {
        useToast.error(useT('common.errorDeleting'));
      });

      return response;
    },

    // Event/SponsorGroup
    async addSponsorGroup(body) {
      const response = await useApi.post(`/events/${this.event._id}/sponsor-groups`, { body }).catch(() => {
        useToast.error(useT('common.errorSavingChanges'));
      });

      return response;
    },

    async updateSponsorGroup({ id, body }) {
      const response = await useApi.put(`/events/${this.event._id}/sponsor-groups/${id}`, { body }).catch(() => {
        useToast.error(useT('common.errorSavingChanges'));
      });

      return response;
    },

    async deleteSponsorGroup(id) {
      const response = await useApi.delete(`/events/${this.event._id}/sponsor-groups/${id}`).catch(() => {
        useToast.error(useT('common.errorDeleting'));
      });

      return response;
    },

    // Event/FeedbackSurvey/Questions
    async fetchFeedbackSurveyQuestions() {
      const response = await useApi.get(`/events/${this.event._id}/feedback-survey/questions`).catch(() => {
        useToast.error(useT('common.errorLoadingData'));
      });

      return response;
    },

    async addFeedbackSurveyQuestions(body) {
      const response = await useApi.post(`/events/${this.event._id}/feedback-survey/questions`, { body }).catch(() => {
        useToast.error(useT('common.errorSavingChanges'));
      });

      return response;
    },

    async updateFeedbackSurveyQuestions({ id, body }) {
      const response = await useApi.put(`/events/${this.event._id}/feedback-survey/questions/${id}`, { body }).catch(() => {
        useToast.error(useT('common.errorSavingChanges'));
      });

      return response;
    },

    async deleteFeedbackSurveyQuestions(id) {
      const response = await useApi.delete(`/events/${this.event._id}/feedback-survey/questions/${id}`).catch(() => {
        useToast.error(useT('common.errorDeleting'));
      });

      return response;
    },

    // Event/ActivePageTemplate
    async fetchActivePageTemplate() {
      const response = await useApi.get(`/templates/active-template?event=${this.event._id}`).catch(() => {
        useToast.error(useT('common.errorLoadingData'));
      });

      this.activePageTemplate = response;

      return response;
    },
  },

  getters: {
    getDays(state) {
      return getEventDays({
        startDate: state.event?.general?.startDate,
        endDate: state.event?.general?.endDate,
        timezone: state.event?.general?.timezone,
      });
    },

    getAllProperties(state) {
      const properties = state.event?.guestlist?.properties || [];

      if (!state.event) {
        console.warn('No event found');
      }

      return [...(this.getFixedProperties() || []), ...properties];
    },

    getProperty() {
      const properties = this.getAllProperties;
      return (id) => properties.find((property) => property.id === id);
    },

    isAfterStartDate(state) {
      return useDayjs().isAfter(state.event?.general?.startDate);
    },

    isBeforeEndDate(state) {
      return useDayjs().isBefore(state.event?.general?.endDate);
    },

    defaultLang(state) {
      return state.event?.general?.langs?.[0];
    },

    hasMultipleLangs(state) {
      return state.event?.general?.langs?.length > 1;
    },

    getLangOptions(state) {
      return (state.event?.general?.langs || []).map((lang) => useGeneralStore().getLang(lang));
    },

    getFixedProperties() {
      const fixedProperties = [
        {
          id: 'email',
          [`_${this.defaultLang}`]: {
            name: useT('poly.email'),
          },
          type: 'text',
        },
        {
          id: 'confirmationStatus',
          [`_${this.defaultLang}`]: {
            name: useT('guest.confirmation'),
          },
          type: 'text',
        },
        {
          id: 'confirmationDate',
          [`_${this.defaultLang}`]: {
            name: useT('guest.confirmationTime'),
          },
          type: 'text',
        },
        {
          id: 'attendanceStatus',
          [`_${this.defaultLang}`]: {
            name: useT('guest.checkIn'),
          },
          type: 'text',
        },
        {
          id: 'attendanceDate',
          [`_${this.defaultLang}`]: {
            name: useT('guest.checkInTime'),
          },
          type: 'text',
        },
        {
          id: 'declineMessage',
          [`_${this.defaultLang}`]: {
            name: useT('guest.declineMessage'),
          },
          type: 'text',
        },
      ];

      if (this.hasMultipleLangs) {
        fixedProperties.push({
          id: 'lang',
          [`_${this.defaultLang}`]: {
            name: useT('common.language'),
          },
          type: 'select',
          options: this.getLangOptions.map((lang) => ({
            id: lang.id,
            icon: lang.icon,
            [`_${this.defaultLang}`]: {
              value: lang.text,
            },
          })),
        });
      }

      return () => fixedProperties.map((property) => ({
        ...property,
        isFixed: true,
      }));
    },

    isInPlanTier(state) {
      const currentTier = state.event?.plan?.config?.tier;
      if (!currentTier) {
        console.warn('No plan tier found');
      }

      return (tier) => Array.isArray(tier) ? tier.includes(currentTier) : tier === currentTier;
    },

    propertiesCascaderOptions(state) {
      const propertiesWithOptions = (state.event?.guestlist?.properties || [])
        .filter((property) => property.type === 'select' || property.type === 'multiSelect');

      return propertiesWithOptions.map((property) => ({
        id: property.id,
        text: property?.[`_${this.defaultLang}`]?.name,
        children: property.options.map((option) => ({
          id: option.id,
          text: option?.[`_${this.defaultLang}`]?.value,
        })),
      }));
    },

    hasImperialSystem(state) {
      return ['America/', 'Pacific/Honolulu', 'Africa/Monrovia', 'Asia/Yangon'].some((timezone) => state.event?.general?.timezone?.startsWith(timezone));
    },

    getPropertiesSortedByTableViewColumns(state) {
      return _sortBy(state.event?.guestlist?.properties, (property) => {
        const columnIndex = state.event?.guestlist?.tableView?.columns?.indexOf(property.id);
        const propertyIndex = state.event?.guestlist?.properties?.indexOf(property);

        return columnIndex === -1 ? 1000 + propertyIndex : columnIndex;
      });
    },

    hasActiveRoommates(state) {
      return state.activePageTemplate?.sections?.some((section) => section.type === 'formBasic' && section.data.roommatesMode === 'allowed');
    },
  },
});
